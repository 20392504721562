import {ErrorHandler, Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {HttpErrorResponse} from '@angular/common/http'
import {ConfigService} from '../config/config.service'
import {differenceInMinutes} from 'date-fns'
import {LocalStorageService} from '../storage/local-storage.service'
import {ErrorMonitor} from './error-monitoring.types'
import {Logger} from '../logging/logger.types'
import {isPlatformBrowser, isPlatformServer} from '@angular/common'
import {TranslocoService} from '@ngneat/transloco'

export const LAST_CHUNK_FAILED_ERROR_KEY = 'bgo.last-chunk-error-date'

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly configService: ConfigService,
    private readonly localStorageService: LocalStorageService,
    private readonly errorMonitor: ErrorMonitor,
    private readonly logger: Logger,
    transloco: TranslocoService,
    @Inject(PLATFORM_ID) platformId: NonNullable<unknown>,
  ) {
    const config = this.configService.config
    this.errorMonitor.addEventProcessor(event => {
      event.environment = config.stage
      event.release = config.version
      // tags on the fly for the lang which might not yet be set
      if (!event.tags) event.tags = {}
      event.tags['bgo.locale'] = transloco.getActiveLang()

      return event
    })
    this.errorMonitor.setTag('bgo.version', config.version)
    this.errorMonitor.setTag('bgo.environment', config.stage)
    this.errorMonitor.setTag('bgo.ssr', isPlatformServer(platformId))
    this.errorMonitor.setTag('bgo.browser', isPlatformBrowser(platformId))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extractError(error: any) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any) {
    if (this.isChunkLoadingFailedErrorHandled(error)) {
      return
    }

    const extracted = this.extractError(error) || 'Handled unknown error'
    const err = extracted instanceof Error ? extracted : new Error(extracted)
    this.logger.error('An error occured', {error: err})
    this.errorMonitor.captureException(err)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isChunkLoadingFailedErrorHandled(error: any): boolean {
    // handle special case where app was remotely updated
    // lazily loaded chunks referenced by currently old running version no longer exist
    // https://medium.com/fieldcircle/error-loading-chunk-xx-failed-with-angular-lazy-loaded-modules-6c5b1b6f8b8d
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      // if an error occurred less than 5 minutes ago, it may be another issue and we want to avoid infinite reload
      if (this.isLastChunkErrorDateFarEnough()) {
        this.logger.debug('Caught `Loading chunk failed` due to a chunk that no longer exists, reloading the page...')
        this.storeLastChunkErrorDate()
        window?.location?.reload()
        return true
      }
    }

    return false
  }

  private isLastChunkErrorDateFarEnough(): boolean {
    const lastError = this.lastChunkErrorDate()
    if (lastError) {
      return differenceInMinutes(new Date(), lastError) > 5
    } else {
      return true
    }
  }

  private lastChunkErrorDate(): Date | undefined {
    const item = this.localStorageService.getItem(LAST_CHUNK_FAILED_ERROR_KEY)
    if (item) {
      return new Date(item)
    } else {
      return undefined
    }
  }

  private storeLastChunkErrorDate() {
    this.localStorageService.setItem(LAST_CHUNK_FAILED_ERROR_KEY, new Date().toISOString())
  }
}
