import {NgModule} from '@angular/core'
import {AsyncPipe, NgClass, NgOptimizedImage} from '@angular/common'
import {NavbarComponent} from './navbar.component'
import {NavbarLogoComponent} from './navbar-left/navbar-logo/navbar-logo.component'
import {ParentBrandBannerComponent} from './parent-brand-banner/parent-brand-banner.component'
import {NavbarLeftComponent} from './navbar-left/navbar-left.component'
import {NavbarCenterComponent} from './navbar-center/navbar-center.component'
import {NavbarRightComponent} from './navbar-right/navbar-right.component'
import {LoadableModule} from '../../lazy-loading/loadable.module'
import {LanguagePickerModule} from './language-picker/language-picker.module'
import {RouterLink, RouterLinkActive} from '@angular/router'
import {FaIconComponent} from '@fortawesome/angular-fontawesome'
import {BrandingModule} from '../../../shared/components/branding/branding.module'
import {HeroSearchbarComponent} from '../../../craft/hero-searchbar/hero-searchbar.component'
import {NavListModule} from '../../../craft/nav-list/nav-list.module'
import {TranslocoDirective} from '@ngneat/transloco'
import {DirectivesModule} from '../../../shared/directives/directives.module'
import {TooltipComponent} from '../../../craft/tooltip/tooltip.component'
import {HamburgerMenuComponent} from './navbar-right/hamburger-menu/hamburger-menu.component'
import {TabsModule} from '../../../craft/tabs/tabs.modules'
import {DropdownNavigationModule} from '../../../craft/dropdown-navigation/dropdown-navigation.module'

@NgModule({
  exports: [NavbarComponent, ParentBrandBannerComponent, NavbarLogoComponent],
  declarations: [
    NavbarComponent,
    NavbarLogoComponent,
    ParentBrandBannerComponent,
    NavbarLeftComponent,
    NavbarCenterComponent,
    NavbarRightComponent,
  ],
  imports: [
    // lazy loaded modules, to make bundle smaller
    // @defer (deferrable views) won't lazy load the entire module (services, deps, etc),
    // but only components, directives, and pipes, and any associated CSS
    LoadableModule.forRoot({
      moduleConfigs: [
        {
          name: 'auth-shortcuts',
          load: () => import('./navbar-right/auth-shortcuts/auth-shortcuts.module').then(m => m.AuthShortcutsModule),
        },
        {
          name: 'auth-submenu',
          load: () =>
            import('./navbar-right/navbar-side-menu/auth-submenu/auth-submenu.module').then(m => m.AuthSubmenuModule),
        },
      ],
    }),
    TranslocoDirective,
    AsyncPipe,
    NgClass,
    RouterLink,
    BrandingModule,
    FaIconComponent,
    LanguagePickerModule,
    DirectivesModule,
    NgOptimizedImage,
    HeroSearchbarComponent,
    NavListModule,
    RouterLinkActive,
    TooltipComponent,
    HamburgerMenuComponent,
    TabsModule,
    DropdownNavigationModule,
  ],
})
export class NavbarModule {}
