import {booleanAttribute, ChangeDetectionStrategy, Component, input} from '@angular/core'

@Component({
  selector: 'cft-dropdown-navigation',
  templateUrl: './dropdown-navigation.component.html',
  styles: `
    :host {
      @apply absolute block;
      @apply invisible left-[50%] -translate-x-1/2 opacity-0;
      @apply top-3;
    }

    :host(.dropdown--open) {
      @apply visible opacity-100;
    }

    .dropdown__nav {
      @apply relative;
    }

    .dropdown__nav > ul {
      max-height: calc(100vh - 5rem);
      @apply overflow-y-auto;
    }

    .dropdown__nav:before {
      @apply absolute left-1/2 top-[-2px];
      @apply block h-[0.875rem] w-[0.875rem] bg-white;
      content: '';
      transform: rotate(45deg) translateX(-50%);
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {'[class.dropdown--open]': 'open()'},
})
export class DropdownNavigationComponent {
  open = input(false, {transform: booleanAttribute})
}
