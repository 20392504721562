<li class="dropdown__menu-item">
  @if (callToAction?.type === 'Url') {
    <a
      [href]="externalUrl"
      bgoOpenNewTab
      class="flex items-center justify-start whitespace-nowrap px-2 py-1 text-sm font-normal text-president-500 hover:bg-president-50"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  }

  @if (callToAction?.type === 'Path') {
    <a
      [routerLink]="path"
      [routerLinkActive]="'bg-president-50'"
      class="flex items-center justify-start whitespace-nowrap px-2 py-1 text-sm font-normal text-president-500 hover:bg-president-50"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  }

  @if (link) {
    <a
      [routerLink]="link"
      [routerLinkActive]="'bg-president-50'"
      class="flex items-center justify-start whitespace-nowrap px-2 py-1 text-sm font-normal text-president-500 hover:bg-president-50"
    >
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
  }

  <ng-template #content>
    @if (icon?.collection && icon?.name) {
      <i class="{{ icon?.collection }} fa-{{ icon?.name }} text-xl mr-1.5"></i>
    }
    <ng-content></ng-content>
    @if (!!subItem) {
      <fa-icon class="ml-auto box-content pl-1.5" [icon]="faCaretRight" size="lg"></fa-icon>
    }
  </ng-template>

  <ng-content select="[dropdownSubMenu]"></ng-content>
</li>
