<ul class="menu-list" *transloco="let t">
  @let user = user$ | async;
  @if (!user?.pro && !user?.agent && !user?.isGlobalAdministrator) {
    <li class="hidden lg:block">
      <cft-tab-pill [link]="craftsmenLink" class="menu-list__item">
        <fa-icon [icon]="craftsmenIcon"></fa-icon>
        <span class="hidden lg:inline">{{ t('navbar.craftsmen.title') }}</span>
        <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.craftsmen.title') }}</cft-tooltip>
      </cft-tab-pill>
    </li>
    <li class="hidden lg:block">
      <cft-tab-pill class="menu-list__item" [link]="servicesLink">
        <fa-icon [icon]="servicesIcon"></fa-icon>
        <span class="menu-list__item__title">{{ t('navbar.services') }}</span>
        <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.services') }}</cft-tooltip>
      </cft-tab-pill>
    </li>
    <li class="hidden lg:block">
      <cft-tab-pill class="menu-list__item" [link]="articlesLink">
        <fa-icon [icon]="articlesIcon"></fa-icon>
        <span class="menu-list__item__title">{{ t('navbar.articles') }}</span>
        <cft-tooltip class="menu-list__item__tooltip">{{ t('navbar.articles') }}</cft-tooltip>
      </cft-tab-pill>
    </li>
  }
  <li
    [ngClass]="{
      'hidden md:block lg:hidden': !user?.pro && !user?.agent && !user?.isGlobalAdministrator,
    }"
  >
    <cft-tab-pill class="relative" #dropdown (click)="dropdownOpen = !dropdownOpen">
      {{ t('navbar.more') }} <fa-icon [icon]="moreIcon" />
      <cft-dropdown-navigation [open]="dropdownOpen">
        <cft-dropdown-menu-item [link]="craftsmenLink">{{ t('navbar.craftsmen.title') }}</cft-dropdown-menu-item>
        <cft-dropdown-menu-item [link]="servicesLink">{{ t('navbar.services') }}</cft-dropdown-menu-item>
        <cft-dropdown-menu-item [link]="articlesLink">{{ t('navbar.articles') }}</cft-dropdown-menu-item>
      </cft-dropdown-navigation>
    </cft-tab-pill>
  </li>
</ul>
