@let layout = _layout();
@let scrolled = _scrolled();
<div>
  <div
    class="h-full"
    [ngClass]="{
      'bg-president-50': layout.backgroundColour === 'blue',
      'layout-navbar--transparent': layout.transparentNavbar,
      'layout-navbar--scrolled': scrolled === 'scrolling',
    }"
  >
    @let banner = announcementBanner$ | async;
    @if (banner && !layout.hideNavbar && !layout.navAdminMode) {
      <bgo-announcement-banner
        [ngClass]="{'hidden sm:block': layout.hideNavbarOnMobile}"
        [id]="banner.id!"
        [text]="banner.textMessage!"
        [linkText]="banner.linkText!"
        [link]="banner.link!"
      ></bgo-announcement-banner>
    }
    @if (!layout.hideNavbar && !layout.navAdminMode) {
      <bgo-parent-brand-banner [ngClass]="{'!hidden sm:block': layout.hideNavbarOnMobile}"></bgo-parent-brand-banner>
    }
    <header class="sticky top-0 z-40" #header>
      @if (!layout.hideNavbar) {
        <bgo-navbar [ngClass]="{'hidden sm:block': layout.hideNavbarOnMobile}" />
      }
    </header>
    <main
      [ngClass]="{
        'h-layout v-layout': !layout.optOutOfLayout && !layout.navAdminMode,
      }"
      [class.content]="!layout.showMinimalistFooter"
      [class.content-min-footer]="layout.showMinimalistFooter"
    >
      <div
        [ngClass]="{
          '-mt-navbar-thin-with-banner sm:-mt-navbar-with-banner': layout.transparentNavbar && !layout.hideNavbar,
        }"
      >
        <router-outlet bgoBroadcastActivateEvents></router-outlet>
      </div>
    </main>
    <div class="invisible h-[1px]" #shadow>
      <!--        hidden div to fix footer rendering issues on some viewports-->
    </div>
    @defer (on interaction(header); prefetch on hover(header)) {
      <bgo-navbar-side-menu></bgo-navbar-side-menu>
    }
    @defer (on viewport(shadow)) {
      <bgo-footer />
    } @placeholder {
      <div></div>
    }
  </div>
</div>
@if (structuredData$ | async; as json) {
  <bgo-json-ld [json]="json"></bgo-json-ld>
}
