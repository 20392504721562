import {ErrorHandler, Provider} from '@angular/core'
import {ConfigService} from '../config/config.service'
import {LocalStorageService} from '../storage/local-storage.service'
import {GlobalErrorHandler} from './global-error-handler.service'
import {ErrorMonitor, NoopErrorMonitoringService} from './error-monitoring.types'
import {environment} from '../../../environments/environment'
import {SentryErrorMonitoringService} from './error-monitoring.service'
import {Router} from '@angular/router'
import {Logger} from '../logging/logger.types'
import {TranslocoService} from '@ngneat/transloco'

export const provideErrorMonitoring: () => Provider[] = () => [
  {
    provide: ErrorMonitor,
    deps: [Router],
    useFactory: (router: Router) => {
      if (environment.sentry.enabled) {
        return new SentryErrorMonitoringService(router)
      } else {
        return new NoopErrorMonitoringService()
      }
    },
  },
]

export const provideErrorHandler: () => Provider[] = () => [
  {
    provide: ErrorHandler,
    deps: [ConfigService, LocalStorageService, ErrorMonitor, Logger, TranslocoService],
    useClass: GlobalErrorHandler,
  },
]
