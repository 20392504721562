import {booleanAttribute, Component, HostBinding, Input, Output} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faClose} from '@fortawesome/pro-solid-svg-icons/faClose'
import {SystemColour, systemColourMapping} from '../utils/colour.types'
import {ClassBuilder} from '../utils/class-builder'
import {BehaviorSubject} from 'rxjs'

/**
 * The snack bar component for craft, it's a toast.
 *
 * All the inputs are derived from toastr's options object,
 * every field becomes an input for an explicit mapping.
 */
@Component({
  selector: 'cft-snack-bar',
  templateUrl: './snack-bar.component.html',
})
export class SnackBarComponent {
  protected readonly closeIcon = faClose

  @HostBinding('class')
  get classes() {
    return ClassBuilder.parse({
      flex: true,
    })
  }

  get ngClass(): Record<string, unknown> {
    return {
      [`${systemColourMapping[this.color]}`]: true,
    }
  }

  get isAccentColorLight(): boolean {
    switch (this.color) {
      case 'president':
        return true
      default:
        return false
    }
  }

  @Input()
  color: SystemColour = 'primary'

  @Input()
  icon?: IconDefinition

  @Input()
  title?: string

  @Input()
  message!: string

  @Input({transform: booleanAttribute})
  hasCloseButton = false

  @Input()
  progress: undefined | number = undefined

  @Output()
  closed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
}
