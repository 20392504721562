import {ChangeDetectionStrategy, Component, Input} from '@angular/core'

@Component({
  selector: 'cft-dropdown-submenu',
  templateUrl: './dropdown-submenu.component.html',
  styleUrls: ['./dropdown-submenu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownSubmenuComponent {
  submenuOffset = 0

  @Input() set position({
    index,
    parentElementsCount,
    subElementsCount,
  }: {
    index: number
    parentElementsCount: number
    subElementsCount: number
  }) {
    if (subElementsCount / 2 >= index) {
      this.submenuOffset = (index - 1) * -2.5
      return
    }
    if (subElementsCount / 2 + index >= parentElementsCount) {
      this.submenuOffset =
        parentElementsCount - subElementsCount > 0
          ? (index + subElementsCount - parentElementsCount - 1) * -2.25
          : (index - 1) * -2.5
      return
    }
    this.submenuOffset = (subElementsCount / 2 - 0.5) * -2.25
  }

  public get shiftIndicator(): Record<'top', string> {
    return {
      top: `calc( ${-this.submenuOffset}rem + 1.5rem )`,
    }
  }

  public get shiftSubmenu(): Record<'transform', string> {
    return {
      transform: `translateY( ${this.submenuOffset}rem )`,
    }
  }
}
