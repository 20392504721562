import {ChangeDetectionStrategy, Component, ElementRef, HostListener, viewChild} from '@angular/core'
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch'
import {faLightbulb} from '@fortawesome/pro-solid-svg-icons/faLightbulb'
import {faUserHelmetSafety} from '@fortawesome/pro-solid-svg-icons/faUserHelmetSafety'
import {faListUl} from '@fortawesome/pro-solid-svg-icons/faListUl'
import {LinkService} from '../../../i18n/link.service'
import {PUBLIC_ROUTES} from '../../../../shared/routing/public.routes'
import {map} from 'rxjs/operators'
import {AuthStatusService} from '../../../auth/auth-status.service'
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown'

@Component({
  selector: 'bgo-navbar-center',
  templateUrl: './navbar-center.component.html',
  styleUrls: ['./navbar-center.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarCenterComponent {
  readonly searchIcon = faSearch
  readonly articlesIcon = faLightbulb
  readonly servicesIcon = faListUl
  readonly articlesLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.ARTICLE_OVERVIEW)
  readonly craftsmenLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.CRAFTSMEN_REGION_PAGE_ROOT)
  readonly servicesLink = this.linkService.localizedPathTo(PUBLIC_ROUTES.PRO_DIRECTORY_PAGE)
  readonly craftsmenIcon = faUserHelmetSafety
  readonly moreIcon = faCaretDown

  readonly user$ = this.authStatusService.authenticatedUser$.pipe(map(auth => auth.authenticatedUser))

  @HostListener('window:click', ['$event'])
  closeDropdown(event: MouseEvent) {
    if (this.dropdownElementRef()?.nativeElement?.contains(event.target) === false) {
      this.dropdownOpen = false
    }
  }

  protected dropdownElementRef = viewChild('dropdown', {read: ElementRef})

  dropdownOpen = false

  constructor(
    private readonly linkService: LinkService,
    readonly authStatusService: AuthStatusService,
  ) {}
}
