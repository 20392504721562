import {ChangeDetectionStrategy, Component, ContentChild, Input} from '@angular/core'
import {faCaretRight} from '@fortawesome/pro-solid-svg-icons/faCaretRight'
import {DropdownSubmenuComponent} from '../dropdown-submenu/dropdown-submenu.component'
import {CallToAction} from '../../button/call-to-action/call-to-action.component'
import {ApplicationRoute} from '../../utils/application.route'
import {PathService} from '../../utils/path.service'

@Component({
  selector: 'cft-dropdown-menu-item',
  templateUrl: './dropdown-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuItemComponent {
  @Input() icon?: {name: string; collection: string}
  @Input() callToAction?: CallToAction
  @Input() link?: string
  @ContentChild(DropdownSubmenuComponent)
  subItem?: DropdownSubmenuComponent

  readonly faCaretRight = faCaretRight

  constructor(readonly pathService: PathService) {}

  get path(): string | undefined {
    return this.callToAction?.type === 'Path' && this.callToAction?.value
      ? this.pathService.localizedPathTo(this.callToAction.value as ApplicationRoute)
      : undefined
  }

  get externalUrl(): string | undefined {
    return this.callToAction?.type === 'Url' ? (this.callToAction.value as string) : undefined
  }
}
