import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'

import {DropdownNavigationComponent} from './dropdown-navigation.component'
import {DropdownSubmenuComponent} from './dropdown-submenu/dropdown-submenu.component'
import {DropdownMenuItemComponent} from './dropdown-menu-item/dropdown-menu-item.component'
import {DirectivesModule} from '../../shared/directives/directives.module'

@NgModule({
  declarations: [DropdownNavigationComponent, DropdownMenuItemComponent, DropdownSubmenuComponent],
  imports: [CommonModule, RouterModule, FontAwesomeModule, DirectivesModule],
  exports: [DropdownNavigationComponent, DropdownMenuItemComponent, DropdownSubmenuComponent],
})
export class DropdownNavigationModule {}
