<ng-container *transloco="let t">
  @if (
    {
      colour: colour$ | async,
    };
    as ctx
  ) {
    <div
      class="py-2xs"
      [ngClass]="{
        'additional-banner__white': ctx.colour === 'white',
        'additional-banner__president': ctx.colour === 'transparent',
      }"
    >
      <div class="flex justify-between px-md py-2xs sm:px-xl">
        <div class="flex items-center gap-xs">
          <span class="text-sm font-medium">{{ t('navbar.aCompanyOf') }}</span>
          <cft-tab-pill small external [link]="mobiliarLink">
            <bgo-mobiliar-logo size="2xs" [negative]="ctx.colour === 'transparent'" hasLink="false"></bgo-mobiliar-logo>
            <fa-icon class="ml-xs" size="xs" [icon]="externalLinkIcon" />
          </cft-tab-pill>
        </div>
        <div class="flex items-center gap-lg">
          <cft-tab-pill class="!hidden sm:!flex" small [link]="aboutUsLink">
            <fa-icon size="xs" [icon]="aboutUsIcon" />{{ t('navbar.aboutUs') }}
          </cft-tab-pill>
          <span class="additional-banner__divider">|</span>
          <bgo-language-picker-dropdown />
        </div>
      </div>
    </div>
  }
</ng-container>
