<div class="flex h-full items-center" *transloco="let t">
  <button
    type="button"
    class="menu btn inline-flex items-center gap-xs border px-md"
    (click)="sideMenuService.show()"
    [attr.aria-label]="t('navbar.menu')"
  >
    <fa-icon [icon]="faBars"></fa-icon>
    <span class="hidden sm:inline">{{ t('navbar.menu') }}</span>
  </button>
</div>
